import { createStore, persist } from 'easy-peasy'

import authStore from './auth'
import countryStore from './country'

const store = createStore(
  {
    auth: persist(authStore, {
      storage: 'localStorage',
      allow: ['user', 'credentials']
    }),
    country: persist(countryStore, {
      storage: 'localStorage',
      allow: ['currency']
    })
  },
  {
    name: 'SinioWebStore'
  }
)

export default store
