import { action } from 'easy-peasy'

const countryStore = {
  currency: 'MYR',
  setCurrency: action((state, payload) => {
    const { currency } = payload

    if (currency) state.currency = currency
  })
}

export default countryStore
